
import {
    defineComponent, PropType, ref, Ref
} from 'vue';
import { account as accountRequest } from '@/data/';
import HttpRequest from '@/util/axios.config';
import { setting as postalCodeRule } from '@/methods/rule';

interface FormData {
    ID: number;
    BillingTitle: string;
    Contactor: string;
    Street: string;
    City: string;
    Postcode: string;
    Country: string;
    TelePhone: string;
    Fax: string;
}
type UserType = 'property' | 'propertyCommunity'|'distributor' | 'installer';
export default defineComponent({
    props: {
        userType: {
            type: String as PropType<UserType>
        }
    },
    setup(props, { emit }) {
        const formRef = ref();
        const urls = ref('');
        const getFormData = () => {
            const formData: Ref<FormData> = ref({
                ID: 0,
                BillingTitle: '',
                Contactor: '',
                Street: '',
                City: '',
                Postcode: '',
                Country: '',
                TelePhone: '',
                Fax: ''
            });
            switch (props.userType) {
                case 'property':
                case 'propertyCommunity':
                    accountRequest.getPropertyBillingInfo((data: FormData) => { formData.value = data; });
                    urls.value = 'v3/web/common/billingInfo/setPM';
                    break;
                case 'installer':
                    accountRequest.getInstallerBillingInfo((data: FormData) => { formData.value = data; });
                    urls.value = 'v3/web/common/manage/editBillingInfo';
                    break;
                case 'distributor':
                    accountRequest.getDistributorBillingInfo((data: FormData) => { formData.value = data; });
                    break;
                default:
                    break;
            }
            return formData;
        };
        const formData = getFormData();

        const submit = () => {
            formRef.value.validate((valid: boolean) => {
                if (valid) {
                    HttpRequest.post(urls.value, formData.value, () => {
                        emit('close');
                        emit('success');
                    });
                }
            });
        };

        const countryOption = addressOption;
        const rules = {
            BillingTitle: [
                {
                    required: true, whitespace: true, message: WordList.RuleCommonLabelEmpty2.format(WordList.TabelBillBillingTitle), trigger: 'blur'
                }
            ],
            Contactor: [
                {
                    required: true, whitespace: true, message: WordList.RuleCommonLabelEmpty2.format(WordList.TabelBillATTN), trigger: 'blur'
                }
            ],
            Country: [
                { required: true, message: WordList.RuleCommonLabelEmpty2.format(WordList.ProperAllTextCountryRegion), trigger: 'change' }
            ],
            Street: [
                {
                    required: true, whitespace: true, message: WordList.RuleCommonLabelEmpty2.format(WordList.TabelCommunitiesStreet), trigger: 'blur'
                }
            ],
            City: [
                {
                    required: true, whitespace: true, message: WordList.RuleCommonLabelEmpty2.format(WordList.TabelCommunitiesCity), trigger: 'blur'
                }
            ],
            Postcode: [
                {
                    validator: postalCodeRule.onlyNumberAndNotEmpty,
                    trigger: 'blur'
                }
            ],
            TelePhone: [
                {
                    required: true, whitespace: true, message: WordList.RuleCommonLabelEmpty2.format(WordList.TabelBillTEL), trigger: 'blur'
                }
            ],
            Email: [
                {
                    required: true, whitespace: true, message: WordList.RuleEmailEmpty, trigger: 'blur'
                }
            ]
        };

        return {
            formRef,
            urls,
            rules,
            formData,
            submit,
            countryOption
        };
    }
});
